<template>
  <client-only>
    <ion-button v-bind="$attrs" :style="computedStyle">
      <slot></slot>
    </ion-button>
    <template #fallback>
      <button v-bind="$attrs" :style="computedStyle" class="default-btn">
        <slot></slot>
      </button>
    </template>
  </client-only>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  style: {
    type: Object,
    default: () => ({}),
  },
});

const computedStyle = computed(() => ({
  // Add any default styles for both ion-button and regular button here
  ...props.style,
}));
</script>
<style lang="sass" scoped>
.default-btn
  background-color: #999
  color: white
  border-radius: 20px
  text-transform: unset
</style>