<template>
  <div class="wrapper d-block align-items-center w-100">
    <div v-for="(button, index) of buttons" :key="`${button.href}-${index}`" class="d-inline-block">
      <router-link :to="button.href">
        <SSRSafeIonButton :style="buttonStyle">
          {{ button.label }}
        </SSRSafeIonButton>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Button } from '../types';
import SSRSafeIonButton from '@/web/components/SSRSafeIonButton.vue';

const props = defineProps({
  buttons: {
    type: Array,
    default: () => [],
  },
  style: {
    type: Object,
    default: () => ({}),
  },
});

const buttons = computed<Button[]>(() => toRef(props.buttons).value as Button[]);
const buttonStyle = toRef(props, 'style');
onBeforeMount(() => {
  // Force a re-render of the component on the client-side
  buttonStyle.value = { ...props.style };
});
</script>

<style lang="sass" scoped>
</style>
