<template>
  <div class="page-header p-3" :class="{ inverting: invert }">
    <h1 class="title m-0">
      {{ title }}
    </h1>
    <h2 class="subtitle m-0">
      {{ subtitle }}
    </h2>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  invert: {
    type: Boolean,
    default: false,
  },
});
const title = toRef(props, 'title');
const subtitle = toRef(props, 'subtitle');
</script>

<style lang="sass" scoped>
.page-header
  background: #AE38E5
  color: #FFF
  border-radius: 18px
  .title
    font-size: 2.5rem
    font-weight: 700
  .subtitle
    font-size: 1.125rem
    font-weight: 700
  &.inverting
    background: #FFF
    .title
      color: #AE38E5
    .subtitle
      color: #214163

@media(max-width: 520px)
  .page-header
    .title
      font-size: 2.0rem
      font-weight: 700
    .subtitle
      font-size: 0.9rem
      font-weight: 700
</style>
